<div
  style="text-align: end; margin-top: 20px; margin-bottom: 10px"
  id="nonprintable"
>
  <button
    id="btnPrint"
    type="button"
    class="btn btn-primary btn1"
    onclick="print()"
  >
    Print
  </button>
  <!-- <ngx-print-element #element="element" >
      <button id="ele" class="btnPrint" class="btn btn-primary btn1" (click)="element.print('printqr', {printMode: 'template'})" >Print</button>
    </ngx-print-element> -->
  <button
    type="button"
    class="btn btn-warning btn1"
    style="margin-left: 10px"
    (click)="Back()"
  >
    Back
  </button>
</div>

<div id="printqr">
  <div
    style="
      margin-bottom: 3%;
      margin-left: auto;
      margin-right: auto;
      display: block;
    "
    *ngFor="let i of qrArray; let in = index"
  >
    <div
      class="printable"
      style="
        text-align: center;
        display: block;
        margin-bottom: 6%;
        align-items: center;
        display: flex;
      "
    >
      <div style="flex: 0.5"></div>
      <div style="text-align: initial; padding-right: 20px; margin-top: -1%">
        <div style="margin-bottom: 5px">
          {{ lotno.substring(0, 12) }}
        </div>
        <div style="margin-bottom: 5px">
          {{ manufacturing | date: "MMM-yyyy" }}
        </div>
        <div  style="margin-bottom: 5px">{{ expiry }}</div>
        <div style="margin-bottom: 5px">{{ "Rs. " + price }}</div>
        <div style="margin-bottom: 5px">
          {{ fermenter?.substring(0, 5) + (lotno ? lotno.substring(12) : '') }}
          <!-- {{ fermenter.substring(0, 5) + lotno.substring(12) }} -->
        </div>
        <div style="margin-bottom: 5px">
          {{ " I- " + (in + 1) }}-{{ manufacturing | date: "ddMMyyyy" }}
        </div>
      </div>

      <div>
        <qrcode
          [qrdata]="i.url"
          [errorCorrectionLevel]="'M'"
          [elementType]="'url'"
          [width]="150"
        ></qrcode>
        <label style="margin-left: -65%; padding: 10px">{{ i.local }}</label>
      </div>
    </div>
    <hr />
  </div>
</div>
