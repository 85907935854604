import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend,HttpHeaders,HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, from, Observable, Observer, Subject } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { MatTabBody } from '@angular/material/tabs';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../dialog/dialog.component';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class ApiService {

  constructor(
    private http: HttpClient,
    private matsnackbar: MatSnackBar,
    private router: Router,
    private dialog: MatDialog,
    private datePipe: DatePipe
  ) {}

  ///////////////////////////////For get All production Reqceipt////////////////////////////
  getAllproductionReceipt(body: any) {
    return this.http.post(`${environment.baseurluat}/getRawDataPost`, body);
  }

  packSlipListForDispatch(form: any) {
    let formdata = new FormData();
    formdata.set('ou', form.ou);
    formdata.set('pshpkslipdate', form.monthyear);
    return this.http.post(`${environment.baseurluat}/packSlipList`, formdata);
  }
  // tslint:disable-next-line:typedef
  itemCodeListForDispatch(form: any) {
    // tslint:disable-next-line:prefer-const
    let formdata = new FormData();
    formdata.set('ou', form.ou);
    formdata.set('pshpkslipdate', form.monthyear);
    formdata.set('packSlipNumber', form.Pack);
    return this.http.post(`${environment.baseurluat}/itemcodeList`, formdata);
  }
  // tslint:disable-next-line:typedef
  lotnumberListForDispatch(form: any) {
    // tslint:disable-next-line:prefer-const
    let formdata = new FormData();
    formdata.set('ou', form.ou);
    formdata.set('pshpkslipdate', form.monthyear);
    formdata.set('packSlipNumber', form.Pack);
    formdata.set('itemcode', form.Itemcode);
    return this.http.post(`${environment.baseurluat}/lotnumberList`, formdata);
  }
  packslipdata(form: any) {
    // tslint:disable-next-line:prefer-const
    let formdata = new FormData();
    formdata.set('ou', form.ou);
    formdata.set('pshpkslipdate', form.monthyear);
    formdata.set('packSlipNumber', form.Pack);
    formdata.set('itemcode', form.Itemcode);
    formdata.set('lotno', form.LotNo);
    return this.http.post(`${environment.baseurluat}/packSlipData`, formdata);
  }
  // tslint:disable-next-line:typedef

  ///////////////////////////////////////Scan Planned to check if already exists or not/////////////////////////
  qrScanedCode(code: any) {
    let formdata = new FormData();
    formdata.set('plannedsmallboxqrcode', code);
    return this.http.post(
      `${environment.baseurluat}/checkpackedboxforplanned`,
      formdata
    );
  }
  // tslint:disable-next-line:typedef
  dispatchqrScanedCode(code: any, packSlipNumber: any, ou: any) {
    // tslint:disable-next-line:prefer-const
    console.log(packSlipNumber);
    let formdata = new FormData();
    formdata.set('bigboxqrcode', code);
    formdata.set('packSlipNumber', packSlipNumber);
    formdata.set('ou', ou);
    return this.http.post(
      `${environment.baseurluat}/checkbigboxdispatchedbefore`,
      formdata
    );
  }
  // tslint:disable-next-line:typedef
  submitDispatch(
    dispatchForm: any,
    qrcodeForDespatch: any,
    packslipdata: any,
    value2: any,
    value: any
  ) {
    console.log(dispatchForm);
    console.log();
    // tslint:disable-next-line:prefer-const
    const abc = {
      customer_code: packslipdata.psd_orderfromcust,
      customer_name: packslipdata.clo_cust_name,
      country_name: packslipdata.clo_country_name,
      lot_no: dispatchForm.LotNo,
      state_name: packslipdata.clo_state_name,
      city_name: packslipdata.clo_city_name,
      item_code: dispatchForm.Itemcode,
      alt_quantity: dispatchForm.AlterNate,
      packSlipNumber: value2,
      bigboxqrcodelist: qrcodeForDespatch,
      ou: value,
    };
    return this.http.post(
      `${environment.baseurluat}/savedispatchdetails`,
      abc,
      qrcodeForDespatch
    );
  }

  ////////////////////////////////For Saving Small Box Qr Code////////////////////////////////
  postSmallQrcode(array: any) {
    // var form = new FormData();
    // form.append('smallboxqrcode', array);
    const form = { smallboxqrcode: array };
    return this.http.post(
      `${environment.baseurluat}/addnewsmallboxqrcode`,
      form
    );
  }


  ///////////////////////////////////////Download API/////////////////////////
  getInnerBoxData(payloadType: string, productionReceipt: any): Observable<any> {
  //  console.log("download called in services")
    const payload = {
      'type': payloadType,
      'production_receipt': productionReceipt
    };

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http.post(`${environment.baseurluat}/getinnerboxgenerated
    `, payload,
    httpOptions)

  }

  ////////////////////////////OU List////////////////////////////////////
  fetchOuList() {
    return this.http.get(`${environment.baseurluat}/oulist`);
  }

  //////////////////////////// small pack size////////////////////

  smallpacksize(form: any) {
    var formdata = new FormData();
    formdata.set('itemcode', form.itemcode),
      formdata.set('itemname', form.itemname),
      formdata.set('stockuom', form.stockuom),
      formdata.set('alternateuom', form.alternateuom),
      formdata.set('smallpackingsize', form.smallpacksize);
    formdata.set('price', form.mrp);
    formdata.set('company_name', form.company_name);
    formdata.set('companycode_name', form.companycode_name);
    formdata.set('price_effective_fromdate', form.price_effective_fromdate);
    formdata.set('price_effective_todate', form.price_effective_todate);
    return this.http.post(
      `${environment.baseurluat}/addnewdetailforsmallpackingitem`,
      formdata
    );
  }
  /////////////////////////Screen List//////////////////////////////////
  fetchScreenList() {
    return this.http.get(`${environment.baseurluat}/qrcodescreenlist`);
  }

  ////////////////////////Fetch Role//////////////////////////////////
  fetchrole() {
    return this.http.get(`${environment.baseurluat}/rolelist`);
  }
  ///////////////////////Create User//////////////////////////////////
  createUser(form: any) {
    var formdata = new FormData();
    formdata.set('username', form.username.toUpperCase()),
      formdata.set('ou', form.ou),
      formdata.set('screen', form.screen),
      formdata.set('password', form.password);

    return this.http.post(`${environment.baseurluat}/createuser`, formdata);
  }

  /////////////////////////////Fetch User List/////////////////////////////
  UserList() {
    return this.http.get(`${environment.baseurluat}/getdetailsofusers`);
  }

  /////////////////////login/////////////////////////////////////
  login(form: any) {
    var formdata = new FormData();

    formdata.set('username', form.username);
    formdata.set('password', form.password);

    return this.http.post(`${environment.baseurluat}/userlogin`, formdata);
  }

  unplannedlist(date: any, form: any) {
    var formdata = new FormData();
    formdata.set('rcudouinstid', form);
    formdata.set('rcuhreceiptdate', date);

    return this.http.post(
      `${environment.baseurluat}/getUnplannedRawDataPostreceipt`,
      formdata
    );
  }
  date( form: any) {
    var formdata = new FormData();
    formdata.set('fromdate',form.fromdate );
    formdata.set('todate',form.todate);
    formdata.set('generatingStatus',form.generatingStatus);

    return this.http.post(
      `${environment.baseurluat}/pendingProductionReceiptForGenerate`,
      formdata
    );
  }

  unplannedlistbyID(form: any, ou: any, date: any) {
    var formdata = new FormData();
    formdata.set('rcudouinstid', ou);
    formdata.set('rcuhreceiptdate', date);
    formdata.set('rcudreceiptno', form);
    return this.http.post(
      `${environment.baseurluat}/getUnplannedRawDataPost`,
      formdata
    );
  }

  /////////////////////////////////save unplanned box///////////////////

  saveunplanned(form: any) {
    const formdata = { unplanned: form };
    return this.http.post(
      `${environment.baseurluat}/addnewsmallboxforunplanned`,
      formdata
    );
  }

  ////////////////////////////////////save outer box for planned////////////////

  saveplannedouter(form: any, array: any) {
    var array1 = array.reverse();
    const body = {
      PlannedDetails: form,
      PlannedSmall: array1,
    };
    console.log(body);
    return this.http.post(
      `${environment.baseurluat}/addbigboxforplanned`,
      body
    );
  }

  ////////////////////////////////////api for details when scan///////////////////

  scandetails(value: any) {
    return this.http.get(
      `${environment.baseurluat}/generateqrcode?packingSlipNumber=${value.packslipnumber}&itemCode=${value.item_code}&ou=3&lotNo=${value.lot_no}&AlternateQty=${value.alternate_qty}`
    );
  }

  //////////////////////////////////////tracking/////////////////////////////

  tracklocation(url: any, type: any) {
    var formdata = new FormData();
    if (type == 'innerBox') {
      formdata.set('smallboxqrcode', url);
    } else if (type == 'outerBox') {
      formdata.set('bigboxqrcode', url);
    } else if (type == 'UnplannedInnerBox') {
      formdata.set('unplannedInnerBox', url);
    }
    return this.http.post(
      `${environment.baseurluat}/trackingqrcodeinfov1`,
      formdata
    );
  }
  tracklocationnew(url: any, type: any) {
    var formdata = new FormData();
    if (type == 'innerBox') {
      formdata.set('smallboxqrcode', url);
    } else if (type == 'outerBox') {
      formdata.set('bigboxqrcode', url);
    } else if (type == 'UnplannedInnerBox') {
      formdata.set('unplannedInnerBox', url);
    }
    return this.http.post(
      `${environment.baseurluat}/qrinfo`,
      formdata
    );
  }

  /////////////////////////////////Reprint for planned///////////////////////////////////

  Reprint(form: any) {
    console.log(form);
    var formdata = new FormData();
    {
      formdata.set('productionreceipt', form.product_receipt.rcpd_receipt_no),
        formdata.set('type', form.boxtype);
    }

    return this.http.post(
      `${environment.baseurluat}/reprintqrcodeinfoforplanned`,
      formdata
    );
  }

  ////////////////mixing qrcode reprint///////////////////

  MixingReprint(form: any) {
    console.log(form);
    var formdata = new FormData();
    {
      formdata.set('packslipnumber', form.packslip.psd_pkslipno);

      formdata.set('type', form.boxtype);
      formdata.set('ou', form.ou);
    }

    return this.http.post(
      `${environment.baseurluat}/reprintqrcodeinfoformixing`,
      formdata
    );
  }

  //////////////////////////////Unplanned Scan to check if this box alreday exists or not /////////////////////////////
  checkUnplannedSmall(qrcode: any) {
    let formdata = new FormData();
    formdata.set('plannedsmallboxqrcode', qrcode);
    return this.http.post(
      `${environment.baseurluat}/checkpackedboxforunplanned`,
      formdata
    );
  }

  ////////////////////////////////Save Unplanned big/Outer box///////////////////////////////////////////

  saveUnplannedOuter(details: any, array: any) {
    var array1 = array.reverse();
    const body = {
      UnPlannedDetails: details,
      UnPlannedSmall: array1,
    };
    console.log(body);
    return this.http.post(
      `${environment.baseurluat}/addbigboxforunplanned`,
      body
    );
  }

  //////////////////////////////////Reprint Unplanned///////////////////////////////////////////////

  reprintunplanned(form: any, event: any) {
    var formdata = new FormData();
    {
      formdata.set('productionreceipt', event),
        formdata.set('type', form.boxtype);
      formdata.set('lotno', form.lotno);
      formdata.set('itemcode', form.itemcode);
    }

    return this.http.post(
      `${environment.baseurluat}/reprintqrcodeinfoforunplanned`,
      formdata
    );
  }

  ////////////////////////////////// Snackbar////////////////////////////////

  snackbar(value: any, color: any) {
    return this.matsnackbar.open(value, 'X', {
      duration: 6000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: [color],
    });
  }
  snackbar_(value: any, color: any) {
    return this.matsnackbar.open(value, 'X', {
      duration: 1000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: [color],
    });
  }

  ////////////////////////////////  save in print log table ////////////////////////////

  saveforprint(form: any) {
    console.log(form,'form')
    return this.http.post(`${environment.baseurluat}/senddataforprinter`, form);
  }
  ///////////////////////////////////////////Save edit User/////////////////////////////

  editUser(form: any) {
    var formdata = new FormData();
    formdata.set('username', form.username.toUpperCase()),
      formdata.set('ou', form.ou),
      formdata.set('screen', form.screen),
      formdata.set('password', form.password);
    formdata.set('status', form.active);

    return this.http.post(`${environment.baseurluat}/updateuser`, formdata);
  }

  /////////////////////////////////////Get Edit USer by id//////////////////////////////////

  getUserById(id: any) {
    return this.http.get(`${environment.baseurluat}/getuserbyid?userid=${id}`);
  }

  ///////////////////////////////////////Get Remaining Boxes//////////////////////////////
  getRemainingbox(
    type: any,
    productionreceipt: any,
    item: any,
    lot: any,
    alternate: any
  ) {
    var formdata = new FormData();
    formdata.set('type', type);
    formdata.set('productionreceipt', productionreceipt);
    formdata.set('itemcode', item);
    formdata.set('lotno', lot);
    formdata.set('alternateqty', alternate);

    return this.http.post(
      `${environment.baseurluat}/getlistofunpackedsmallboxes`,
      formdata
    );
  }

  // //////////////////////////////////dispatch_information///////////////////////////////
  dispactchinfo(outerqrcode: any, packslip: any, lotno: any, itemcode: any) {
    var formdata = new FormData();
    formdata.set('outerqrcode', outerqrcode);
    formdata.set('packslip', packslip);
    formdata.set('lotno', lotno);
    formdata.set('itemcode', itemcode);
    return this.http.post(
      `${environment.baseurluat}/packageverificationbypackslip`,
      formdata
    );
  }

  //////////////////////////////////submit received outer qr code ////////////////////////////

  submitReceivedQr(packslipdata: any, qrcodeArray: any, lotno: any, item: any) {
    const body = {
      outerqrcodes: qrcodeArray,
      packslip: packslipdata,
      lotno: lotno,
      itemcode: item,
    };
    return this.http.post(
      `${environment.baseurluat}/packagereceivebypackslip`,
      body
    );
  }

  //////////////////////////////////packSlipListforreceiving//////////////////////////////////////
  packSlipListforreceiving(date: any, ou: string) {
    var formdata = new FormData();
    formdata.set('pshpkslipdate', date);
    formdata.set('ou', ou);
    return this.http.post(
      `${environment.baseurluat}/packSlipListforreceiving`,
      formdata
    );
  }

  ////////////////////////////////////////////////////  itemCode List For Received Dispatch//////////////////////
  itemCodeListForReceivedDispatch(form: any) {
    let formdata = new FormData();
    formdata.set('ou', form.ou);
    formdata.set('pshpkslipdate', form.monthyear);
    formdata.set('packSlipNumber', form.Pack);
    return this.http.post(
      `${environment.baseurluat}/itemcodeListforreceiving`,
      formdata
    );
  }

  ////////////////////////////////////////////////////  lotnumber List For Received Dispatch//////////////////////////////
  lotnumberListForReceivedDispatch(form: any) {
    // tslint:disable-next-line:prefer-const
    let formdata = new FormData();
    formdata.set('ou', form.ou);
    formdata.set('pshpkslipdate', form.monthyear);
    formdata.set('packSlipNumber', form.Pack);
    formdata.set('itemcode', form.Itemcode);
    return this.http.post(
      `${environment.baseurluat}/lotnumberListforreceiving`,
      formdata
    );
  }

  /////////////////////////////////////////////get receive count at receiving//////////////////////////////////////////
  receiveCount(form: any) {
    let formdata = new FormData();
    formdata.set('ou', form.ou);
    formdata.set('pshpkslipdate', form.monthyear);
    formdata.set('packSlipNumber', form.Pack);
    formdata.set('itemcode', form.Itemcode);
    formdata.set('lotno', form.LotNo);
    return this.http.post(
      `${environment.baseurluat}/getreceivecountatreceiving`,
      formdata
    );
  }

  ////////////////////////////////////////////////when Qrcode scanned redirecrt to next page parameter Api//////////////////////////////////////////

  TenParamter(url: any) {
    var form = new FormData();
    form.set('url', url);
    return this.http.post(`${environment.baseurluat}/getqrcodedata`, form);
  }

  // /////////////////////////////////Delete Print Queue/////////////////////////////////////

  deletequeue(form: any) {
    return this.http.post(`${environment.baseurluat}/deleteprintqueue`, form);
  }

  ////////////////////////////get itemcode for unplanned///////////////////////////////

  itemcodeunplanned(body: any) {
    return this.http.post(
      `${environment.baseurluat}/getUnplannedRawDataPostItemCode`,
      body
    );
  }

  ///////////////////////////////get lot No unplanned////////////////////////////////////////////////////////
  lotNoUnplanned(form: any) {
    return this.http.post(
      `${environment.baseurluat}/getUnplannedRawDataPostLotNumber`,
      form
    );
  }

  //////////////////////////////////////////#api for getting rest of data for unplanned//////////////////////////////

  restDataunplanned(form: any) {
    return this.http.post(
      `${environment.baseurluat}/getUnplannedRawDataPost`,
      form
    );
  }

  print() {
    const body = {
      bigboxqrcode:
        'http://qrcode.mykumpany.com/scan-details?bigboxNumber=UKH/2122/PRC00171@20G24@5@THPL1000@THPL/2122/01-005@POB@OuterBox1',
      manufacturingdate: '15/03/2021 00:00:00',
      expirydate: '12/02/2025 00:00:00',
      price: '100',
      lotnumber: 'HBFL/2122/01-009',
    };
    return this.http.get(
      `${environment.baseurluat}/addbigboxforplannedpdf?bigboxNumber=UKH/2122/PRC00747@20F03@1@BTP1000@BTP-/2122/02-002@POB@OuterBox1&manufacturingdate=12/02/2014&expirydate=14/09/2023&price=100&lotnumber=BTP-/2122/02-002`
    );
  }

  ////////////////////////////////get new packslip api////////////////////
  getnewpackSlip(form: any) {
    const formdata = new FormData();
    formdata.set('pshpkslipdate', form.date);
    formdata.set('ou', form.ou);

    return this.http.post(
      `${environment.baseurluat}/packSlipListnew`,
      formdata
    );
  }
  getnewpackSlipnew(form: any) {
    const formdata = new FormData();
    formdata.set('pshpkslipdate', form.date);
    formdata.set('ou', form.ou);

    return this.http.post(
      `${environment.baseurluat}/packSlipListnewforreprint`,
      formdata
    );
  }



  ////////////////////packslip details like customer code ,name etc////////////////////////
  newPackslipDetails(form: any) {
    const formdata = new FormData();
    formdata.set('pshpkslipdate', form.date);
    formdata.set('ou', form.ou);
    formdata.set('packSlipNumber', form.packslip.psd_pkslipno);

    return this.http.post(
      `${environment.baseurluat}/packSlipDataNew`,
      formdata
    );
  }

  /////////////////packslip new seach api to check if it already dispatched or not//////////////
  newPackslipSearch(form: any, qrcode: any) {
    var formdata = new FormData();
    formdata.set('pshpkslipdate', form.date);
    formdata.set('ou', form.ou);
    formdata.set('packSlipNumber', form.packslip.psd_pkslipno);
    formdata.set('bigboxqrcode', qrcode.trim());

    return this.http.post(
      `${environment.baseurluat}/checkbigboxdispatchedbeforenew`,
      formdata
    );
  }

  ////////////////////new save packslip data/////////////////////////////////
  saveNewPackslipData(form: any, qrcodelist: any) {
    const body = {
      ou: form.ou,
      packSlipNumber: form.packslip.psd_pkslipno,
      customer_name: form.customername,
      customer_code: form.customercode,
      country_name: form.country,
      state_name: form.state,
      city_name: form.city,
      bigboxqrcodelist: qrcodelist,
      pshpkslipdate: form.date,
    };

    return this.http.post(
      `${environment.baseurluat}/savedispatchdetailsnewv1`,
      body
    );
  }

  ////////////////////get new packslip for received///////////////////

  getReceivednew(form: any) {
    const formdata = new FormData();
    formdata.set('packslipdate', form.date);
    formdata.set('ou', form.ou);

    return this.http.post(
      `${environment.baseurluat}/getpacksliplistforreceiving`,
      formdata
    );
  }

  ///////////////////// to Allocate PAckslip////////////////////////
  packslipAllocated(ouid: any, packslip: any) {
    var form = new FormData();
    form.set('ou', ouid), form.set('packslipnumber', packslip.psd_pkslipno);

    return this.http.post(
      `${environment.baseurluat}/ipl_packslipallocate`,
      form
    );
  }

  //////////////new received packslip data ///////////////

  packSlipDataReceived(value: any, body: any) {
    var form = new FormData();
    if (value == null) {
      form.set('packSlipNumber', body.packslip.packslipnumber);
    } else {
      form.set('packSlipNumber', body.packslip);
    }

    form.set('ou', body.ou);
    form.set('pshpkslipdate', body.date);

    return this.http.post(
      `${environment.baseurluat}/packSlipDataNewforreceiving`,
      form
    );
  }

  /////////////////////new received data for recevied boxes in itemcode/////////////

  receivedData(form: any, itemcode: any) {
    var formdata = new FormData();
    formdata.set('packslipnumber', form.packslip.packslipnumber);
    formdata.set('ou', form.ou);
    formdata.set('psh_pkslipdate', form.date);
    formdata.set('itemcode', itemcode);

    return this.http.post(
      `${environment.baseurluat}/receivedqrcodeforreceivebasedonpackslipou`,
      formdata
    );
  }

  /////////////////////new recevied  data for pending boxes in itemcode/////////////

  pendingData(form: any, itemcode: any) {
    var formdata = new FormData();
    formdata.set('packslipnumber', form.packslip.packslipnumber);
    formdata.set('ou', form.ou);
    formdata.set('psh_pkslipdate', form.date);
    formdata.set('itemcode', itemcode);

    return this.http.post(
      `${environment.baseurluat}/pendigqrcodeforreceivebasedonpackslipou`,
      formdata
    );
  }

  /////////////////////new recevied  data for rejected boxes in itemcode/////////////
  rejectData(form: any, itemcode: any) {
    var formdata = new FormData();
    formdata.set('packslipnumber', form.packslip.packslipnumber);
    formdata.set('ou', form.ou);
    formdata.set('psh_pkslipdate', form.date);
    formdata.set('itemcode', itemcode);

    return this.http.post(
      `${environment.baseurluat}/rejectedqrcodeforreceivebasedonpackslipou`,
      formdata
    );
  }

  /////////////////////new recevied  data for mark rejected boxes in itemcode/////////////
  markRejectBoxes(form: any, boxid: any, reason: any) {
    console.log(boxid);
    let type: any, item: any;
    let sentences = boxid.bigboxqrcode.split(/[?=@]+/);
    console.log(sentences);
    if (sentences[7] == 'POB') {
      type = 'P';
    } else {
      type = 'U';
    }

    if (sentences[1] == 'bigboxNumber') {
      item = sentences[5];
    } else if (sentences[1] == 'UnplannedOuterBox') {
      item = sentences[4];
    }
    var formdata = new FormData();
    formdata.set('packslipnumber', form.packslip.packslipnumber);
    formdata.set('ou', form.ou);
    formdata.set('psh_pkslipdate', form.date);
    formdata.set('itemcode', item);
    formdata.set('bigboxid', boxid.bigboxid);
    formdata.set('type', type);
    formdata.set('reason', reason);

    return this.http.post(
      `${environment.baseurluat}/markrejectedqrcodeforreceivebasedonpackslipou`,
      formdata
    );
  }

  submitReceived(form: any, qrcode: any) {
    let type: any, item: any;
    let sentences = qrcode.split(/[?=@]+/);
    console.log(sentences);
    if (sentences[7] == 'POB') {
      type = 'P';
    } else {
      type = 'U';
    }

    if (sentences[1] == 'bigboxNumber') {
      item = sentences[5];
    } else if (sentences[1] == 'UnplannedOuterBox') {
      item = sentences[4];
    }
    var formdata = new FormData();
    formdata.set('packslipnumber', form.packslip.packslipnumber);
    formdata.set('ou', form.ou);
    formdata.set('psh_pkslipdate', form.date);
    formdata.set('itemcode', item);
    formdata.set('bigbox', qrcode);
    formdata.set('type', type);
    return this.http.post(
      `${environment.baseurluat}/markqrcodeofpackslipreceivedforreceivebasedonpackslipou`,
      formdata
    );
  }

  ///////////////////get list of itemcode in pending outer bxes////////////////////////
  pendingOuterItemCode() {
    return this.http.get(
      `${environment.baseurluat}/itemlistforfindingpendigouterbox`
    );
  }

  ////////////////////get list of pendng outer box////////////////////

  pendingOuterBox(form: any) {
    console.log(form);
    var formdata = new FormData();
    formdata.set('ou', form.ou),
      formdata.set('itemcode', form.itemcode.item_code);
    return this.http.post(
      `${environment.baseurluat}/listofpendigouterboxes`,
      formdata
    );
  }

  //////////////////dispatch qty in dispatch screen/////////////
  dispatchQty(form: any) {
    return this.http.post(
      `${environment.baseurluat}/scanneddispatchqrcode`,
      form
    );
  }

  ////////////////////deleete single qrcode from dispatch//////////////
  singleDeleteDispatch(form: any, itemcode: any, value: any) {
    console.log(form, value, 'ppppppppppppppppppppppppppppppppppppppp');
    const formdata = new FormData();
    formdata.set('ou', form.ou);
    formdata.set('packslipnumber', form.packslip.psd_pkslipno);
    formdata.set('itemcode', itemcode);
    formdata.set('bigboxid', value.bigboxid);
    formdata.set('bigboxtype', value.bigboxtype);
    formdata.set('bigboxqrcode', value.bigboxqrcode);

    return this.http.post(
      `${environment.baseurluat}/deletedispatchqrcodebyqrcode`,
      formdata
    );
  }

  /////////////////dleete alll qrcode from dispatch////////////////////
  deleteAllDispatch(form: any) {
    let formdata = new FormData();

    formdata.set('ou', form.ou);
    formdata.set('packslipnumber', form.packslip.psd_pkslipno);

    return this.http.post(
      `${environment.baseurluat}/deletealldispatchqrcode`,
      formdata
    );
  }

  //////////////////////////////get all production receipt from printer/////////////////////

  PrinterProductionReceipt(value: any) {
    const formdata = new FormData();
    formdata.set('printeraddress', value);
    return this.http.post(
      `${environment.baseurluat}/getlistofproductionreceiptofnpd`,
      formdata
    );
  }

  //////////////////////mark queue in printer//////////////////////////

  markPrinter(printervalue: any, receipt: any) {
    console.log(printervalue, 'printttt');

    const form = new FormData();
    form.set('printeraddress', printervalue);
    form.set('productionreceipt', receipt);

    return this.http.post(
      `${environment.baseurluat}/markprintedbasedonprinteraddress`,
      form
    );
  }

  ////////////////// save printed using normal printer
  NormalPRinterSave(value: any) {
    console.log(value,'array')
    return this.http.post(
      `${environment.baseurluat}/normalprinterapisetdata`,
      value
    );
  }

  //////////////////get Normal printer Qrcodes to create pdf//////////
  GetNormalPrinterQrCode(value: any) {
    const form = new FormData();
    form.set('id', value);
    return this.http.post(
      `${environment.baseurluat}/normalprinterapigetdata`,
      form
    );
  }

  /////////////////////mixing select packslip////////////////////////

  selectMixingPackslip(value: any): Observable<any> {
    console.log(value);
    const formdata = new FormData();
    formdata.set('ou', value.ou);
    formdata.set('packSlipNumber', value.packslip.psd_pkslipno);
    formdata.set('pshpkslipdate', value.date);

    return this.http.post(
      `${environment.baseurluat}/packSlipDataNewformix`,
      formdata
    );
  }

  /////////////////////scan inner qrcode to check ///////////

  scanInnerMixing(form: any) {
    return this.http.post(
      `${environment.baseurluat}/checkscannedqrcodefordispatchmix`,
      form
    );
  }

  ////////////////////save outer box in mixing screen///////////////

  createBigBoxMixing(form: any, array: any) {
    const body = {
      ou: form.ou,
      packslipnumber: form.packslip.psd_pkslipno,
      innerqrcodes: array,
    };

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }

    return this.http.post(`${environment.baseurluat}/addbigboxformix`, body, httpOptions);
  }

  //////////////////////mixing qrcode dispatch///////////////////
  dispatchmixingQrcode(form: any, qrcode: any) {
    console.log(form, 'serviceeeeeeeee');

    const body = {
      ou: form.ou,
      packslipnumber: form.packslip.psd_pkslipno,
      pkslipdate: form.date,
      bigboxqrcode: qrcode,
      customer_name: form.customername,
      customer_code: form.customercode,
      country_name: form.country,
      state_name: form.state,
      city_name: form.city,
    };

    return this.http.post(
      `${environment.baseurluat}/dispatchedbigboxmix`,
      body
    );
  }

  //////////////////////allocate packslip///////////////////

  allocatepackslipMix(form: any) {
    const formdata = new FormData();
    {
      formdata.set('ou', form.ou);
      formdata.set('packslipnumber', form.packslip.psd_pkslipno);
    }
    return this.http.post(
      `${environment.baseurluat}/ipl_packslipallocatemix`,
      formdata
    );
  }

  ////////////get Inner Box of item code in Mixing QRcide////////////
  getInnerQRcodeOfItem(form: any, itemcode: any) {
    const formdata = new FormData();
    {
      formdata.set('ou', form.ou);
      formdata.set('packslipnumber', form.packslip.psd_pkslipno);
      formdata.set('itemcode', itemcode);
    }
    return this.http.post(
      `${environment.baseurluat}/getlistofscannedinnerqrinmixobbyitemcode`,
      formdata
    );
  }

  /////////////delete all from dispatch form Mixing///////////////////////////
  deleteQrCodeMixing(form: any) {
    const formdata = new FormData();
    {
      formdata.set('ou', form.ou);
      formdata.set('packslipnumber', form.packslip.psd_pkslipno);
    }
    return this.http.post(
      `${environment.baseurluat}/deleteqrcodeformixboxdispatch`,
      formdata
    );
  }

  ////////////////////all dispatch list mixing//////////////////////
  MixDispatchList(form: any) {
    const formdata = new FormData();
    {
      formdata.set('ou', form.ou);
      formdata.set('packslipnumber', form.packslip.psd_pkslipno);
    }
    return this.http.post(
      `${environment.baseurluat}/getlistofscannedmixouterqrcodeatdipatch`,
      formdata
    );
  }

  ////////////////////delete single//////////////////
  deleteSingle(form: any, value: any) {
    const formdata = new FormData();
    {
      formdata.set('ou', form.ou);
      formdata.set('packslipnumber', form.packslip.psd_pkslipno);
      formdata.set('mixbigboxid', value);
    }
    return this.http.post(
      `${environment.baseurluat}/deleteqrcodeformixboxdispatchbymobqrcode`,
      formdata
    );
  }

  printNormalPRinter(formdata: any) {
    let url = `${environment.baseurluat}/normalprinterapisetdata`;

    this.http.post(url, formdata).subscribe(
      (res: any) => {
        if (res.responseCode == 200) {
          const url = this.router.serializeUrl(
            this.router.createUrlTree(['/print-Inner'], {
              queryParams: { id: res.responseMessage.data.inserted_id },
            })
          );
          const newTab = window.open(url, '_blank');
        } else if (res.responseCode == 0) {
          this.snackbar(res.responseMessage.message, 'red-snackbar');
        }
      },
      (err: any) => {
        this.snackbar(err.message, 'red-snackbar');
      }
    );
  }

  printNormalMix(formdata: any) {
    let url = `${environment.baseurluat}/normalprinterapisetdataformix`;

    this.http.post(url, formdata).subscribe(
      (res: any) => {
        if (res.responseCode == 200) {
          const url = this.router.serializeUrl(
            this.router.createUrlTree(['/print-Inner'], {
              queryParams: { id: res.responseMessage.data.inserted_id },
            })
          );
          const newTab = window.open(url, '_blank');
        } else if (res.responseCode == 0) {
          this.snackbar(res.responseMessage.message, 'red-snackbar');
        }
      },
      (err: any) => {
        this.snackbar(err.message, 'red-snackbar');
      }
    );
  }

  DominoPrinter(body: any) {
    return this.http.post(
      `${environment.baseurluat}/resetmarkprintedstatus`,
      body
    );
  }

  markprintedqrcodefordomino(body: any) {
    return this.http.post(
      `${environment.baseurluat}/markprintedqrcodefordomino`,
      body
    );
  }

  updatedominoprintercount(printingaddress: any, count: any) {
    const body = {
      printingaddress: printingaddress,
      count: count,
    };
    return this.http.post(
      `${environment.baseurluat}/updatedominoprintercount`,
      body
    );
  }

  printer() {
    return this.http.get(`${environment.baseurluat}/printerlist`);
  }

  printerPasswodVerification(printeraddress: any, printerpassword: any) {
    var formdata = new FormData();
    let userid: any = window.localStorage.getItem('user_id');

    formdata.set('userid', userid);
    formdata.set('printeraddress', printeraddress);
    formdata.set('printerpassword', printerpassword);

    return this.http.post(
      `${environment.baseurluat}/verifyprinterpassword`,
      formdata
    );
  }

  createPrinterPassword(form: any) {
    var formdata = new FormData();
    formdata.set('printeraddress', form.printer);
    formdata.set('printerpassword', form.password);
    formdata.set('userid', '');

    return this.http.post(
      `${environment.baseurluat}/createprinterpassword`,
      formdata
    );
  }

  fetchPrinterPasswordList() {
    return this.http.get(`${environment.baseurluat}/getprinterpasswordlist`);
  }

  updatePrinterPassword(form: any) {
    var formdata = new FormData();

    //formdata.set('userid', localStorage.getItem('user_id')!),
    formdata.set('printeraddress', form.printeraddress),
      formdata.set('printerpassword', form.password);
    formdata.set('oldprinterpassword', form.oldpassword);

    return this.http.post(
      `${environment.baseurluat}/updateprinterpassword`,
      formdata
    );
  }
  ///////////////////////////////////////////////////////////////////////////
  getoucodefordailystockreport() {
    return this.http.get(
      `${environment.baseurluat}/getoucodefordailystockreport`
    );
  }

  addMailInStock(form: any) {
    var formdata = new FormData();
    if (form.oucode.includes('All')) {
      formdata.set('oucode', 'All');
    } else {
      formdata.set('oucode', form.oucode);
    }

    formdata.set('oumail', form.email);

    return this.http.post(
      `${environment.baseurluat}/addmailfordailystockreport`,
      formdata
    );
  }

  getdailstockreport(oucode: any) {
    const formdata = new FormData();
    formdata.set('oucode', oucode);
    return this.http.post(
      `${environment.baseurluat}/getdailstockreport`,
      formdata
    );
  }

  deletemailfordailystockreport(value: any) {
    var formdata = new FormData();
    formdata.set('id', value.id);

    return this.http.post(
      `${environment.baseurluat}/deletemailfordailystockreport`,
      formdata
    );
  }
  normalPrintList(form:any){
    const body={
      ou:3,
      production_receipt:form.productionReceipt,
    }
    return this.http.post(
      `${environment.baseurluat}/normalprinthist`,
      body
    );
  }

  createMedialoss(form: any) {
      const data = {
        userid: localStorage.getItem('user_id'),
        username:localStorage.getItem('username'),
        ml_year: form.year,
        ml_month: form.month,
        ml_item_code: form.itemCode,
        ml_item_description: form.itemDescription,
        ml_qty_inkgltr: form.qty,
        ml_volume: form.volume,
        ml_rate: form.rate,
        ml_fermenter_no: form.fermentor,
        ml_remarks: form.remark,
        ml_product_code:form.product,
        created_by: localStorage.getItem('user_id')
      };
console.log(data,'body')
    return this.http.post(`${environment.baseurluat}/media_loss_create`, data);
  }

  editMedialoss(form: any) {
      const data = {
        userid: localStorage.getItem('user_id'),
        username:localStorage.getItem('username'),
        id:form.id ,
        ml_year: form.year,
        ml_month: form.month,
        ml_item_code: form.itemCode,
        ml_item_description: form.itemDescription,
        ml_qty_inkgltr: form.qty,
        ml_volume: form.volume,
        ml_rate: form.rate,
        ml_fermenter_no: form.fermentor,
        ml_remarks: form.remark,
        ml_product_code:form.product,
        updated_by: localStorage.getItem('user_id')

      };
console.log(data,'body')
    return this.http.post(`${environment.baseurluat}/media_loss_edit`, data);
  }



  editRejected(form: any) {
    const data = {

      id:form.id ,
      party_name:form.year,
      material_name:form.month,
      invoice_no:form.itemCode,
      invoice_date:this.datePipe.transform(form.itemDescription, 'yyyy-MM-dd'),
      unit:form.qty,
      po_extra_qty:form.rate,
      sgst_cgst_igst:form.fermentor,
      freight:form.Frieght,
      total_amount:form.Total,
      gate_entry_no:form.Gate,
      gate_entry_date:this.datePipe.transform(form.GateDate, 'yyyy-MM-dd'),
      gate_entry_time:form.Time,
      lfno:form.lf,
      erp_grin_no:form.ERP,
        grin_date:this.datePipe.transform(form.GRIND, 'yyyy-MM-dd'),
        indent_no:form.Indent,
        po_no:form.po,
        vehicle_no:form.vehicle,
        gr_no:form.GRno,
        gr_date:this.datePipe.transform(form.GRdate, 'yyyy-MM-dd'),
        transport_name:form.Transporter,
        vendor_test_repot:form.vendortest,
        make_name:form.Makename,
        gst_percentage:form.volume,
      userid: localStorage.getItem('user_id'),
      username:localStorage.getItem('username'),

      // created_by: localStorage.getItem('user_id')


    };
console.log(data,'body')
  return this.http.post(`${environment.baseurluat}/mis_rejection_report_update`, data);
}
  MedialossList() {
    const data = {
      fromyear:'',
      toyear:'',
      frommonth:'',
      tomonth:'',
      itemcode:'',

     };
    return this.http.post(`${environment.baseurluat}/media_loss_get`,data);
  }
  getrejection() {
    const data = {


     };
    return this.http.post(`${environment.baseurluat}/mis_rejection_report_get`,data);
  }
  filterMedialoss(form:any,fyear:any,fmonth:any,tyear:any,tmonth:any) {
    const data = {
     fromyear:fyear,
     toyear:tyear,
     frommonth:fmonth,
     tomonth:tmonth,
     itemcode:form.itemCode,

    };
console.log(data,'body')
  return this.http.post(`${environment.baseurluat}/media_loss_get`, data);
}


  createlabour(form: any) {
      const data = {

        userid: localStorage.getItem('user_id'),
        username:localStorage.getItem('username'),
         labor_utlsn_date:this.datePipe.transform(form.itemCode, 'yyyy-MM-dd'),
         warehouse:form.month,
         post_prdn_labor_mandays:form.itemDescription,
         total_labor_utilized_mandays:form.qty,
         post_production_labour_cost:form.pplc,
         total_labour_cost:form.tlc,
         created_by:localStorage.getItem('user_id'),
         remarks:form.remark

      };
console.log(data,'body')
    return this.http.post(`${environment.baseurluat}/labor_utlzn_create`, data);
  }

  editlabour(form: any) {
      const data = {
        id:form.id ,
        userid: localStorage.getItem('user_id'),
        username:localStorage.getItem('username'),
         labor_utlsn_date:this.datePipe.transform(form.itemCode, 'yyyy-MM-dd'),
         warehouse:form.month,
         post_prdn_labor_mandays:form.itemDescription,
         total_labor_utilized_mandays:form.qty,
         post_production_labour_cost:form.pplc,
         total_labour_cost:form.tlc,


         updated_by:localStorage.getItem('user_id') ,
         remarks:form.remark

      };
console.log(data,'body')
    return this.http.post(`${environment.baseurluat}/labor_utlzn_edit`, data);
  }
  deletelabour(id: any) {
      const data = {
        ids:id,
      };
console.log(data,'body')
    return this.http.post(`${environment.baseurluat}/labor_utlzn_delete`, data);
  }
  labourList() {
    const data = {
      fromdate:'',
      todate:'',


     };
    return this.http.post(`${environment.baseurluat}/labor_utlzn_get`,data);
  }

  labourupload(file:any) {
    const username = localStorage.getItem('username') || '';
const userId = localStorage.getItem('user_id') || '';
    var formdata = new FormData();
    formdata.set('username', username);
    formdata.set('userid', userId);
    formdata.set('file', file);


  return this.http.post(`${environment.baseurluat}/labor_utlzn_bulk_upload`, formdata);
}

mediaupload(file:any) {
  const username = localStorage.getItem('username') || '';
const userId = localStorage.getItem('user_id') || '';
  var formdata = new FormData();
  formdata.set('username', username);
  formdata.set('userid', userId);
  formdata.set('file', file);


return this.http.post(`${environment.baseurluat}/media_loss_bulk_upload`, formdata);
}
rejectionupload(file:any) {
  const username = localStorage.getItem('username') || '';
const userId = localStorage.getItem('user_id') || '';
  var formdata = new FormData();
  formdata.set('username', username);
  formdata.set('userid', userId);
  formdata.set('file', file);


return this.http.post(`${environment.baseurluat}/mis_rejection_report_bulkupload`, formdata);
}
deadupload(file:any) {
  const username = localStorage.getItem('username') || '';
const userId = localStorage.getItem('user_id') || '';
  var formdata = new FormData();
  formdata.set('username', username);
  formdata.set('userid', userId);
  formdata.set('file', file);


return this.http.post(`${environment.baseurluat}/deadndnonmvngitembulkupload`, formdata);
}
deletemedia(id: any) {
  const data = {
    ids :id,
  };
console.log(data,'body')
return this.http.post(`${environment.baseurluat}/media_loss_delete`, data);
}
deletedead(id: any) {
  const data = {
    userid: localStorage.getItem('user_id'),
    username:localStorage.getItem('username'),
    ids :id,
  };
console.log(data,'body')
return this.http.post(`${environment.baseurluat}/deadndnonmvngitemdelete`, data);
}
filterlabour(form:any) {
  const data = {
   fromdate:this.datePipe.transform(form.fromDate, 'yyyy-MM-dd'),
   todate:this.datePipe.transform(form.toDate, 'yyyy-MM-dd'),


  };
console.log(data,'body')
return this.http.post(`${environment.baseurluat}/labor_utlzn_get`, data);
}
createdead(form: any) {
  const data = {
    userid: localStorage.getItem('user_id'),
    username:localStorage.getItem('username'),
    dead_nd_non_moving_item: form.Dead,
  unit: form.Unit,
  price: form.Price,
  item_code: form.itemcode,
  closing_stock: form.Closing,
  deadndnonmoving_value: form.Value,
  remarks: form.Remark,
  closing_date: this.datePipe.transform(form.Date, 'yyyy-MM-dd'),
  };
console.log(data,'body')
return this.http.post(`${environment.baseurluat}/deadndnonmvngitemcreate`, data);
}
editdead(form: any) {
  const data = {
    id:form.id,
    userid: localStorage.getItem('user_id'),
    username:localStorage.getItem('username'),
    dead_nd_non_moving_item: form.Dead,
  unit: form.Unit,
  price: form.Price,
  item_code: form.itemcode,
  closing_stock: form.Closing,
  deadndnonmoving_value: form.Value,
  remarks: form.Remark,
  closing_date: this.datePipe.transform(form.Date, 'yyyy-MM-dd'),
  };
console.log(data,'body')
return this.http.post(`${environment.baseurluat}/deadndnonmvngitemedit`, data);
}
deadList() {
  const data = {
    closingfromdate:'',
    closingtodate:''


   };
  return this.http.post(`${environment.baseurluat}/deadndnonmvngitemget`,data);
}

filterdead(form:any) {
  const data = {
    closingfromdate:this.datePipe.transform(form.fromDate, 'yyyy-MM-dd'),
    closingtodate:this.datePipe.transform(form.toDate, 'yyyy-MM-dd'),


  };
  console.log(data,'body')
return this.http.post(`${environment.baseurluat}/deadndnonmvngitemget`, data);
}

getLocation() {
  return new Observable((observer: any) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position: any) => {
          observer.next(position);
          observer.complete();
        },
        (error: any) => {
          observer.error(error);
        }
      );
    } else {
      observer.error('Geolocation is not supported by this browser.');
    }
  });
}
customerinfo(form: any,fullUrl:any,latitude:any,longitude:any) {
  const data = {
    url:fullUrl,
    name:form.name,
    mobile_no:form.phone,
    location:form.location,
    lat:latitude,
    long: longitude
  };
console.log(data,'body')
return this.http.post(`${environment.baseurluat}/customerinfocollection`, data);
}

itemdetail(form: any) {
  const data = {
    item_code :form.itemCode,
  };

console.log(data,'body')
return this.http.post(`${environment.baseurluat}/getitem_desc_report`, data);
}
itemdetail2(form: any) {
  const data = {
    item_code :form.itemcode,
  };

console.log(data,'body')
return this.http.post(`${environment.baseurluat}/getitem_desc_report`, data);

}

rejectioncreate(form: any) {
  const data = {
    party_name:form.year,
    material_name:form.month,
    invoice_no:form.itemCode,
    invoice_date:this.datePipe.transform(form.itemDescription, 'yyyy-MM-dd'),
    unit:form.qty,
    po_extra_qty:form.rate,
    sgst_cgst_igst:form.fermentor,
    freight:form.Frieght,
    total_amount:form.Total,
    gate_entry_no:form.Gate,
    gate_entry_date:this.datePipe.transform(form.GateDate, 'yyyy-MM-dd'),
    gate_entry_time:form.Time,
    lfno:form.lf,
    erp_grin_no:form.ERP,
      grin_date:this.datePipe.transform(form.GRIND, 'yyyy-MM-dd'),
      indent_no:form.Indent,
      po_no:form.po,
      vehicle_no:form.vehicle,
      gr_no:form.GRno,
      gr_date:this.datePipe.transform(form.GRdate, 'yyyy-MM-dd'),
      transport_name:form.Transporter,
      vendor_test_repot:form.vendortest,
      make_name:form.Makename,
      gst_percentage:form.volume,
    userid: localStorage.getItem('user_id'),
    username:localStorage.getItem('username'),

    // created_by: localStorage.getItem('user_id')
  };
console.log(data,'body')
return this.http.post(`${environment.baseurluat}/mis_rejection_report_create`, data);
}
filterPending(form: any) {
  const data = {
    rcphreceiptdatefrom:form.fromdate ,
    rcphreceiptdateto:form.todate,
  };
console.log(data,'body')
return this.http.post(`${environment.baseurluat}/getListOfProductionReceiptForPendingPrdnRcpt`, data);
}
flagApi(data: any) {
  const body = {
   data:data,
   userid: localStorage.getItem('user_id'),

  };
console.log(data,'body')
return this.http.post(`${environment.baseurluat}/markLoosePendingProductionReceiptForGenerate`, body);
}
unmark(data: any) {
  const body = {
   data:data,
   userid: localStorage.getItem('user_id'),
  };
console.log(data,'body')
return this.http.post(`${environment.baseurluat}/unmarkLoosePendingProductionReceiptForGenerate`, body);
}
flagApi2(data: any) {
  const body = {
   data:{
    production_receipt_date:data.fromdate,
    production_receipt:data.todate,
    export_flag:data.export ? 1 : (data.export === '' ? 0 : 0),
    loose_flag:data.loose ? 1 : (data.loose === '' ? 0 : 0),
   },
   userid: localStorage.getItem('user_id'),

  };
console.log(body,'body')
return this.http.post(`${environment.baseurluat}/markLoosePendingProductionReceiptForGenerate`, body);
}
getdominoprintercount(printeraddress:any){
  const body = {
    printeraddress:printeraddress

   };
 console.log(body,'body')
 return this.http.post(`${environment.baseurluat}/getdominoprinterheadcount`, body);
}
submitdominoprintercount(form:any){
  const body = {

    printeraddress:form.printer,
    count:form.qrcode

   };
 console.log(body,'body')
 return this.http.post(`${environment.baseurluat}/setdominoprinterheadcount`, body);
}
///////////////////get Detail For Small Package Item////////////////////////
getDetailForSmallPackageItem(data:any,page:number){
// console.log("api data",data)
const params={
  itemname:data.itemname,
  itemcode:data.itemcode,
  pageno:page,
  size:Number(data.size),
  price_effective_fromdate:data.price_effective_fromdate,
      price_effective_todate:data.price_effective_todate,
}



 return this.http.post(`${environment.baseurluat}/getnewdetailforsmallpackingitem`,params);
}



}
