<div class="container">
  <!-- 
  <div class="row mt-3">
    <div class="col-12">
      <table class="table table-bordered" >
        <thead class="thead-dark" >
          <tr>
            <th>Box Scanned</th>
            <th></th>
          </tr>
        </thead>
        <tbody  style="background-color: white;" >
          <tr>
            <th>{{boxtype}}</th>
            <td>{{boxName}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div> -->

  <!-- <table class="table table-bordered mt-3" *ngFor='let item of detailsArray' style="width: 70%; margin: auto;">
    <thead>
      <tr style="background-color: rgb(74, 119, 7); color: white;" class="text-center">
        <th style="width: 30%;">Parameter</th>
        <th> Value</th>
      </tr>
    </thead>
    <tbody *ngFor="let i of item |keyvalue " style="background-color: white;" >
      <tr>
        <th >{{i.key}}</th>
        <td><span *ngIf="i.key=='Website_link_product_section'else elseBlock"><img src="{{i.value}}" alt="image" width="100"
          height="100"></span>
          <ng-template #elseBlock>
           {{i.value}}
          </ng-template>
        </td>
      </tr>
    </tbody>
  </table> -->

  <table class="table table-bordered mt-3" style="width: 70%; margin: auto">
    <thead>
      <tr
        style="background-color: rgb(74, 119, 7); color: white"
        class="text-center"
      >
        <th style="width: 30%">Parameter</th>
        <th>Value</th>
      </tr>
    </thead>
    <tbody style="background-color: white">
      <tr>
        <th>Name Of the Product</th>
        <td>{{ detailsArray[0].Name_of_the_product }}</td>
      </tr>
      <tr>
        <th>Marketed By</th>
        <td>{{ detailsArray[0].Marketed_By }}</td>
      </tr>
      <tr>
        <th>Batch Number</th>
        <td>
          {{
            detailsArray[0].Batch_Number.length > 12
              ? detailsArray[0].Batch_Number.substring(0, 12)
              : detailsArray[0].Batch_Number
          }}
        </td>
      </tr>
      <tr>
        <th>Date Of Manufacture</th>
        <td>{{ detailsArray[0].Date_of_Manufacture | date : "MMM-yyyy" }}</td>
      </tr>

      <tr>
        <th>Expiry Date</th>
        <td>{{ detailsArray[0].Expiry_date }}</td>
      </tr>

      <tr>
        <th>Cautionary Symbol As Per Toxicity Classification</th>
        <td>
          <img
            src="{{
              detailsArray[0]
                .Cautionary_Symbol_as_per_the_toxicity_classification
            }}"
            width="400"
            height="200"     
            alt="image"
          />
        </td>
      </tr>
      <tr></tr>
      <tr>
        <th>Customer Care Details</th>
        <td>{{ detailsArray[0].Customer_Care_details }}</td>
      </tr>
      <tr>
        <th>Antidote Statement</th>
        <td>{{ detailsArray[0].Antidote_Statement }}</td>
      </tr>
      <tr>
        <th>Registration Number</th>
        <td>{{ detailsArray[0].Registration_No }}</td>
      </tr>
      <tr>
        <th>Name Of the Manufacture</th>
        <td>{{ detailsArray[0].Name_of__the_Manufacturer }}</td>
      </tr>

      <tr>
        <th>Unique Identifier</th>
        <td>{{ detailsArray[0].Unique_Identifier }}</td>
      </tr>

      <tr>
        <th>Leaflet</th>

        <td>
          <a
            href="../../assets/IPL Catalogue (English Version) 14-11-2022.pdf"
            download
            ><i class="fa fa-file-pdf-o" style="font-size: 48px; color: red"></i
          ></a>
        </td>

        <!-- <td><a href="{{ detailsArray[0].Leaflet }}">pdf</a></td> -->
      </tr>
    </tbody>
  </table>
</div>
