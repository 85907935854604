
<div class="main"
[ngClass]="code=='DNK' ? 'bgDNK' : 'bgIPL'"
>
    <div class="d-flex flex-column flex-md-row" >

        <div class=" d-flex col">
            <div class="middelHeading">
                <h1  style="font-size: 44px; font-weight: 600;" *ngIf="code=='DNK'; else elseBlock">PROVIDING INNOVATIVE CROP SOLUTION TO INDIAN AGRICULTURE FROM PAST 4 DECADES</h1>

                <ng-template #elseBlock><h1 class="mt-5" style="font-size: 44px; font-weight: 900;  color: #023b82c4;">IPL BIOLOGICALS LIMITED A PART OF M2K GROUP</h1></ng-template>
            </div>
          <div class="rightIcon">
            <img *ngIf="code=='DNK'; else elseBlockimg" src="../../../assets/pack5/dhanuka logo.png">

            <ng-template #elseBlockimg><img style="width: 216px; margin-top: 88px; "  src="../../../assets/img/IPL-Logo.png"></ng-template>
          </div>
        </div>

      </div>
      <!-- <div class="MiddleMain"><img src="../../../assets/pack5/anaag.png"
        class="grass" style="top: 513px;"></div> -->

        <div class="d-flex flex-column flex-md-row">
            <div class="col-md-7 col-sm-12 left" style="margin-left: 3%;width:auto;">

                <div class="mb-3">
                    <div class="RightImg">
                      <img *ngIf="code=='DNK'; else elseBlocknew"  class="kissan" style="width: 153%;"
                        src="../../../assets/pack5/background_image.png"  alt="dhanuka">
                        <img *ngIf="code=='DNK';"
                     class="transform"
                        src="../../../assets/pack5/caption_image.png" alt="dhanuka">
                        <ng-template #elseBlocknew>
                          <!-- <h1 class="mt-5 " style="margin: 0 auto; font-size: 44px; font-weight: 700;  line-height: 1.2; text-align: center; color: #2f55a299;">REDEFINING SUSTAINABLE AGRICULTURE VIA MICROBOT TECHNOLOGY</h1> -->
                        </ng-template>
                </div>
                </div>

            </div>
             <ng-template #elseBlocknew>
                          <h3 class="mt-5" style="font-size: 44px; font-weight: 700;  line-height: 1.2; text-align: center; color: #2f55a299;">REDEFINING SUSTAINABLE AGRICULTURE VIA MICROBOT TECHNOLOGY</h3>
                        </ng-template>
            <div class="d-flex justify-content-center col-md-4 col-sm-12 right" style="margin-top: 5%;
            margin-left: 2%; width:auto;">

                <div *ngIf="code=='DNK'; else elseBlockform"  class="card" style="width: 90%;height: 85%;background:#03341e"

                >

                    <div class="card-body">
                      <h4 class="card-title" style="color:white; text-align: center;">Please provide your details to view code verification result and stay
                        connected with Dhanuka.</h4>
                          <form [formGroup]="form" (ngSubmit)="submit()">
                            <div class="mb-3">
                        <label for="formFile" class="form-label">*Name</label>
                        <input class="form-control" formControlName="name" type="text" appAlphabetonly  [ngClass]="{
                            'is-invalid':
                              (form.controls['name'].touched &&
                                form.controls['name'].hasError('required'))
                          }" >
                           <span
                           class="error"
                           *ngIf="
                             form.controls['name'].hasError('required') &&
                             (form.controls['name'].dirty ||
                               form.controls['name'].touched)
                           "
                           >*Name is Required</span
                         >
                    </div>
                    <div class="mb-3">
                        <label for="formFileMultiple" class="form-label">*Mobile Number</label>
                        <input maxlength="10" appNumberonly formControlName="phone" class="form-control" type="text"
                        [ngClass]="{
                            'is-invalid':
                              (form.controls['phone'].touched &&
                                form.controls['phone'].hasError('required'))
                          }">
                          <span
                          class="error"
                          *ngIf="
                            form.controls['phone'].hasError('required') &&
                            (form.controls['phone'].dirty ||
                              form.controls['phone'].touched)
                          "
                          >*Mobile Number is Required</span
                        >
                    </div>
                    <div class="mb-3">
                        <label for="formFileDisabled" class="form-label">Location</label>
                        <input class="form-control" formControlName="location"type="text"
                        [ngClass]="{
                            'is-invalid':
                              (form.controls['location'].touched &&
                                form.controls['location'].hasError('required'))
                          }" >
                          <span
                          class="error"
                          *ngIf="
                            form.controls['location'].hasError('required') &&
                            (form.controls['location'].dirty ||
                              form.controls['location'].touched)
                          "
                          >*location is Required</span
                        >
                    </div>
                    <div class="mb-3 dialoge-button"><button
                      class="proceed-green"

                        >
                        <span>Submit </span></button>
                    </div>
                          </form>


                    </div>
                  </div>
                  <ng-template #elseBlockform>
                <div class="card" style="width: 90%;height: 85%;background:#062872"

                >

                    <div class="card-body">
                      <h4 class="card-title" style="color:white; text-align: center;">Please provide your details to view code verification result and stay
                        connected with IPL BIOLOGICALS.</h4>
                          <form [formGroup]="form" (ngSubmit)="submit()">
                            <div class="mb-3">
                        <label for="formFile" class="form-label">*Name</label>
                        <input class="form-control" formControlName="name" type="text" appAlphabetonly  [ngClass]="{
                            'is-invalid':
                              (form.controls['name'].touched &&
                                form.controls['name'].hasError('required'))
                          }" >
                           <span
                           class="error"
                           *ngIf="
                             form.controls['name'].hasError('required') &&
                             (form.controls['name'].dirty ||
                               form.controls['name'].touched)
                           "
                           >*Name is Required</span
                         >
                    </div>
                    <div class="mb-3">
                        <label for="formFileMultiple" class="form-label">*Mobile Number</label>
                        <input maxlength="10" appNumberonly formControlName="phone" class="form-control" type="text"
                        [ngClass]="{
                            'is-invalid':
                              (form.controls['phone'].touched &&
                                form.controls['phone'].hasError('required'))
                          }">
                          <span
                          class="error"
                          *ngIf="
                            form.controls['phone'].hasError('required') &&
                            (form.controls['phone'].dirty ||
                              form.controls['phone'].touched)
                          "
                          >*Mobile Number is Required</span
                        >
                    </div>
                    <div class="mb-3">
                        <label for="formFileDisabled" class="form-label">Location</label>
                        <input class="form-control" formControlName="location"type="text"
                        [ngClass]="{
                            'is-invalid':
                              (form.controls['location'].touched &&
                                form.controls['location'].hasError('required'))
                          }" >
                          <span
                          class="error"
                          *ngIf="
                            form.controls['location'].hasError('required') &&
                            (form.controls['location'].dirty ||
                              form.controls['location'].touched)
                          "
                          >*location is Required</span
                        >
                    </div>
                    <div class="mb-3 dialoge-button"><button
                      class="proceed-blue"

                        >
                        <span>Submit </span></button>
                    </div>
                          </form>


                    </div>
                  </div></ng-template>

            </div>


        </div>
<!--
        <div class="row">
            <div class="col ">
              <img src="../../../assets/pack5/anaag.png" style="width:100%;">
            </div>
        </div> -->
       <span *ngIf="code=='DNK'">

            <img src="../../../assets/pack5/trust-of.png" style="width:100%;">

</span>
</div>
