import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-kisan',
  templateUrl: './kisan.component.html',
  styleUrls: ['./kisan.component.scss']
})
export class KisanComponent implements OnInit {
  public href: string = "";
  constructor(private router: Router) { }

  ngOnInit(): void {
    this.href = this.router.url;
        console.log(this.router.url);
  }

}
